import { type userType } from '../common-types';

export interface KnowledgeCardTeamSearchMemberNodeType {
	member?: userType | null;
	state?: string | null;
}

export interface KnowledgeCardTeamSearchNodeType {
	team?: {
		id: string;
		displayName?: string | null;
		smallAvatarImageUrl?: string | null;
		members: {
			nodes: KnowledgeCardTeamSearchMemberNodeType[];
		};
	} | null;
	memberCount?: number | null;
}

export interface KnowledgeCardTeamSearchType {
	team: {
		teamSearchV2: {
			nodes: KnowledgeCardTeamSearchNodeType[];
		};
	};
}

export interface KnowledgeCardTeamSearchVariables {
	teamQuery: string;
	orgId: string;
	tenantId: string;
}

export const KnowledgeCardTeamSearchQuery = `
query KnowledgeCardTeamSearchQuery($teamQuery: String!, $orgId: ID!, $tenantId: String!) {
  team {
    teamSearchV2 (
      filter: {
        query: $teamQuery
      }
      first: 1
      organizationId: $orgId
      siteId: $tenantId
    ) @optIn(to: "Team-search-v2") {
      nodes {
        memberCount
        team {
          id
          displayName
          smallAvatarImageUrl
          members{
            nodes {
              member {
                id
                accountId
                name
                picture
                ... on AtlassianAccountUser {
                  email
                  extendedProfile {
                    jobTitle
                    department
                  }
                }
              }
              state
            }
          }
        }
      }
    }
  }
}
`;
