// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const KnowledgeCardsPicture = styled.img({
	borderRadius: '50%',
	width: '64px',
	height: '64px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const KnowledgeCardsTimeText = styled.span({
	color: token('color.text.subtle'),
	fontSize: '12px',
	fontStyle: 'normal',
	fontWeight: '590',
	lineHeight: '16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const KnowledgeCardsLocationText = styled.span({
	color: token('color.text.subtlest'),
	fontSize: '12px',
	fontStyle: 'normal',
	fontWeight: '590',
	lineHeight: '16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const KnowledgeDefaultSectionHeader = styled.span({
	color: token('color.text'),
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: token('font.weight.semibold'),
	lineHeight: '16px',

	// overflow ellipsis css styles
	wordBreak: 'break-all',
	display: '-webkit-box',
	overflow: 'hidden',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const KnowledgeDefaultText = styled.span({
	color: token('color.text'),
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: '400',
	lineHeight: '20px',

	// overflow ellipsis css styles
	wordBreak: 'break-all',
	display: '-webkit-box',
	overflow: 'hidden',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const KnowledgeDefaultSubText = styled.span({
	color: token('color.text.subtlest'),
	fontSize: '11px',
	fontStyle: 'normal',
	fontWeight: '400',
	lineHeight: '14px',

	// overflow ellipsis css styles
	wordBreak: 'break-all',
	display: '-webkit-box',
	overflow: 'hidden',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values
export const EmptyStateText = styled.span({
	color: token('color.text.subtle'),
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: '400',
	lineHeight: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const KnowledgeIconWrapper = styled.div({
	position: 'absolute',
	top: '12px',
	right: token('space.200'),
	paddingInline: token('space.075'),
	paddingBlock: token('space.050'),
	'&:hover': {
		background: token('elevation.surface.hovered'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const KnowledgeCardsTeamCountText = styled.span({
	color: token('color.text.subtle'),
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: token('font.weight.semibold'),
	lineHeight: '16px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const PersonStyles = styled.img({
	borderRadius: '50%',
	width: '32px',
	height: '32px',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles
export const WorkStyles = styled.img({
	borderRadius: '0',
	width: '24px',
	height: '24px',
});
