import { type IntlShape } from 'react-intl-next';

import { getUserRecommendations } from '@atlaskit/smart-user-picker';
import { type ActivityClient } from '@atlassian/recent-work-client';

import {
	NLPSearchErrorState,
	type NLPSearchPersonResultType,
	NLPSearchResultFormat,
	type NLPSearchWhoResultCollaboratorDataType,
	type NLPSearchWhoResultDataType,
	type SearchAIAnswerQueryType,
} from '../../../ai-answer-dialog/types';
import { fetchAggResponse } from '../../../common/fetch';
import { sanitizeAndMapRecentWorkActivity, trimARI } from '../../util';

import {
	KnowledgeCardPrimaryUserDataQuery,
	type KnowledgeCardPrimaryUserDataQueryType,
	type KnowledgeCardPrimaryUserDataVariables,
} from './gql';

const MAX_USERS = 10;

type fetchPersonKnowledgeCardVariables = {
	personQuery: string;
	tenantId: string;
	principalUserId: string;
	intl: IntlShape;
};

export const fetchPersonKnowledgeCardData = ({
	personQuery,
	principalUserId,
	tenantId,
	intl,
}: fetchPersonKnowledgeCardVariables): Promise<SearchAIAnswerQueryType> => {
	try {
		const requestParams = {
			context: {
				contextType: 'contributors',
				productKey: 'people',
				principalId: principalUserId,
				siteId: tenantId,
			},
			includeGroups: false,
			includeTeams: false,
			includeUsers: true,
			maxNumberOfResults: MAX_USERS,
			performSearchQueryOnly: true,
			query: personQuery,
		};

		return getUserRecommendations(requestParams, intl)
			.then((results) => {
				const mappedResults = results.map((result) => ({
					id: result.id,
					name: result.name,
					avatarUrl: result.avatarUrl,
					title: result.title,
				}));
				const finalResult: NLPSearchPersonResultType = {
					user_metadata_only_cards: mappedResults,
				};
				return {
					nlpSearch: {
						nlpResult: JSON.stringify(finalResult),
						uniqueSources: [],
						disclaimer: null,
						errorState: null,
						format: NLPSearchResultFormat.JSON,
					},
				};
			})
			.catch((_error) => {
				return Promise.resolve({
					nlpSearch: {
						errorState: NLPSearchErrorState.NetworkError,
						disclaimer: null,
						format: NLPSearchResultFormat.JSON,
						uniqueSources: [],
					},
				});
			});
	} catch (error) {
		return Promise.resolve({
			nlpSearch: {
				errorState: NLPSearchErrorState.NetworkError,
				disclaimer: null,
				format: NLPSearchResultFormat.JSON,
				uniqueSources: [],
			},
		});
	}
};

type fetchPrimaryUserDataVariables = {
	accountId: string;
	tenantId: string;
	requestingUserId: string;
	activityClient: ActivityClient;
	locale: string;
};

export const fetchPrimaryUserData = ({
	accountId,
	tenantId,
	requestingUserId,
	activityClient,
	locale,
}: fetchPrimaryUserDataVariables): Promise<NLPSearchWhoResultDataType> => {
	const aggPromise = fetchAggResponse<
		KnowledgeCardPrimaryUserDataVariables,
		KnowledgeCardPrimaryUserDataQueryType
	>({
		graphQuery: KnowledgeCardPrimaryUserDataQuery,
		variables: {
			accountId,
			accountIdARI: `ari:cloud:identity::user/${accountId}`,
			tenantId,
			requestingUserId,
		},
	});
	const activityPromise = activityClient.fetchActivities(
		[100],
		{
			limit: 50,
			actors: [`ari:cloud:identity::user/${accountId}`],
		},
		['workedOn'],
		false,
	);
	return Promise.all([aggPromise, activityPromise]).then(([aggResponse, activityResponse]) => {
		const primaryUserData = {
			account_id: accountId,
			name: aggResponse.data?.user.name || '',
			job_title: aggResponse.data?.user.extendedProfile?.jobTitle,
			profile_url: `/people/${accountId}`,
			avatar_url: aggResponse.data?.user.picture,
			email: aggResponse.data?.user.email,
			location: aggResponse.data?.user.extendedProfile?.location,
			department: aggResponse.data?.user.extendedProfile?.department,
			organization: aggResponse.data?.user.extendedProfile?.organization,
			time: aggResponse.data?.user.zoneinfo
				? new Date().toLocaleTimeString(locale, { timeZone: aggResponse.data.user.zoneinfo })
				: undefined,
			collaborators: aggResponse.data?.smarts.recommendedUser.reduce<
				NLPSearchWhoResultCollaboratorDataType[]
			>((acc, node) => {
				if (trimARI(node.user.id) !== accountId) {
					acc.push({
						name: node.user.name,
						picture: node.user.picture,
						title: !!node.user.extendedProfile?.jobTitle
							? node.user.extendedProfile.jobTitle
							: null,
						url: !!node.user.id ? `/people/${trimARI(node.user.id)}` : null,
						accountId: node.user.id,
						department: !!node.user.extendedProfile?.department
							? node.user.extendedProfile.department
							: null,
						email: !!node.user.email ? node.user.email : null,
					});
				}
				return acc;
			}, []),
			viewAllLink: `/people/${accountId}`,
			recentActivity:
				activityResponse.workedOn && activityResponse.workedOn.length > 0
					? sanitizeAndMapRecentWorkActivity(activityResponse.workedOn)
					: [],
		};
		return primaryUserData;
	});
};
